import React, { useEffect } from "react";

import { Award } from "custom-types/Strain";

import { useWeedfetti } from "components/Weedfetti";

type Props = {
  award?: Award;
  celebrationColors?: string[] | null;
};

export const SOTYBanner = ({
  celebrationColors,
  award,
}: Props): JSX.Element | null => {
  const { launchWeedfetti } = useWeedfetti();

  const celebrate = () => {
    // We only want to celebrate when there is data to celebrate about.
    // If you aren't seeing weedfetti and you expect to, check strain admin for proper data
    if (celebrationColors?.length) {
      launchWeedfetti({ colors: celebrationColors });
    }
  };

  useEffect(() => {
    celebrate();
  }, []);

  if (!award?.blurb) {
    return null;
  }

  return (
    <>
      <div
        onClick={celebrate}
        onKeyPress={celebrate}
        role="button"
        tabIndex={0}
        className="mb-lg rounded w-full gradient-animation overflow-hidden"
      >
        <div
          className="text-center text-white py-sm text-xs font-bold uppercase mb-sm"
          style={{ backgroundColor: "black" }}
        >
          {award?.blurb || "Leafly Strain of the Year"}
        </div>
      </div>
      <style jsx={true}>{`
        .gradient-animation {
          background: linear-gradient(
            90deg,
            ${celebrationColors?.length
              ? celebrationColors.join()
              : "#4b2b7e, #a91f64, #eedc12"}
          );
          background-size: 100%;
          animation: GradientAnimation 5s ease 1;
        }
        @media screen and (prefers-reduced-motion: reduce) {
          .gradient-animation {
            animation: none;
          }
        }
        @keyframes GradientAnimation {
          0% {
            background-size: 200%;
            background-position: 0%;
          }
          33% {
            background-size: 200%;
            background-position: 100%;
          }
          67% {
            background-size: 200%;
            background-position: 0%;
          }
          100% {
            background-size: 100%;
          }
        }
      `}</style>
    </>
  );
};

export default SOTYBanner;
